import APIService from "./APIService";

const http = APIService.http;

export default {
  async getRedirectURL(redirect) {
    let res = await http.post(`api/redirect/redirectAndLog/${redirect}`);
    return res.data;
  },
  async getRedirectURLNoLog(redirect) {
    let res = await http.get(`api/redirect/redirect/${redirect}`);
    return res.data;
  },
};
