<template>
  <div></div>
</template>

<script>
import RedirectService from "../services/RedirectService";

export default {
  name: "redirect-view",
  async mounted() {
    // do the redirect
    let redirect = this.$route.query.r;

    let info;
    if (this.$route.query.nolog) {
      info = await RedirectService.getRedirectURLNoLog(redirect);
    } else {
      info = await RedirectService.getRedirectURL(redirect);
    }

    if (info && Object.prototype.hasOwnProperty.call(info, "redirectUrl")) {
      window.location.replace(info.redirectUrl);
    }
  },
};
</script>
